const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function isPhoneNumber(string) {
    if(string.startsWith("+")) {
      string = string.replace("+", "0");
    }
    if(!isNumeric(string)) return false;
    return string.length > 8;
  }

  function formatPhoneNumber(phoneNumberString) {
    var plus = false;
    if(phoneNumberString.startsWith("00")) {
      phoneNumberString = "0" + phoneNumberString.substring(2, phoneNumberString.length);
      plus = true;
    }
    if(phoneNumberString.startsWith("+")) {
      phoneNumberString = phoneNumberString.replace("+", "0");
    }
    var output = phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    
    if(plus) {
      output = "+" + output.substring(1, output.length);
    }
    return output;
  }

  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export {isPhoneNumber, formatPhoneNumber, validateEmail, isValidHttpUrl}