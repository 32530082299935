import React, { useEffect, useRef, useState } from "react";
import EmailIcon from "../assets/icons/email";
import Footer from "../components/footer";
import SearchButton from "../components/searchButton";
import BoxContainer from "../components/box/boxContainer";
import EventBox from "../components/box/eventBox";

import "./aider.css";
import { getFutureHelperExchanges, getHelperExchangeCategories } from "../api/aiderManager";
import { formatClocktime } from "../utils/dateUtils";
import { addNewsletter } from "../api/newsletterManager";
import MultipleSelect from "../components/multipleSelect";
import HelperBox from "../components/box/helperBox";

export default function AiderPage() {

    const firstExecute = useRef(false);
    const [maxAiderCount, setMaxAiderCount] = useState(-1);
    const [aiderCount, setAiderCount] = useState(3);
    const [aider, setAider] = useState([]);
    const [mail, setMail] = useState("");
    const [error, setError] = useState("");
    const [addedNewsletter, setAddedNewsletter] = useState(false);

    const [categories, setCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [category, setCategory] = useState(-1);

    useEffect(() => {
        if(firstExecute.current) return;
        firstExecute.current = true;
        getHelperExchangeCategories((c) => {
            setCategories(c);
            var names = [];
            for(var i = 0; i < c.length; i++) {
                names.push(c[i].name);
            }
            setCategoryNames(names);
        });
        getFutureHelperExchanges((d) => {
            var output = [];
            var maxAider = 0;
            for(var i = 0; i < d.length; i++) {
                var el = d[i];
                output.push({
                    "id": el.id,
                    "title": el.title,
                    "msg": el.always ? "ab sofort" : "",
                    "nextStartTime": el.startTime,
                    "time": el.always ? undefined : formatClocktime(new Date(el.startTime), new Date(el.endTime)),
                    "location": el.location,
                    "club": el.creator.username,
                    "category": el.category
                });
                if(category == -1 || (el.category && categories[category] && el.category == categories[category].value)) {
                    maxAider += 1;
                }
            }
            setMaxAiderCount(maxAider);
            setAider(output);
        });
    })

    var index = 0;
    var realAiderCount = 0;
    aider.map((d, i) => {
        if(category != -1 && d.category != categories[category].value) return;
        if(realAiderCount >= aiderCount) return;
        realAiderCount++;
    })
    return <>
        <div className="page" id="aider-page">
            <h1>Mithelfen, in Berg am Laim: Wir packen an und ein!</h1>
            <p className="text">Ihr plant eine tolle Veranstaltung, braucht dafür aber dringend Helferinnern und Helfer? Ihr wollt was auf die Beine stellen, dafür fehlen aber noch Stühleträgerinnen oder Kuchenbäcker, Zeltaufbauer oder Plakatekleberinnen? Dann schreibt's in unsere Helferbörse: Welche Art von Hilfe braucht ihr, wann und wie lange? Die Helferbörse unterstützt euch dabei, genau die Hilfe zu finden, die ihr braucht!</p>
            <p className="text">Du willst dich engagieren, hast aber vielleicht keine Zeit für regelmäßige Vereinsarbeit? Mit unserer Helferbörse bekommst du die Möglichkeit, dich trotzdem einzubringen - genau bei den Projekten und Aufgaben, die du spannend findest und die dir liegen! Damit tust du nicht nur was Gutes: das macht auch noch Spaß und du lernst neue Leute kennen. Einfach hier eintragen und du wirst regelmäßig über Hilfegesuche informiert!</p>
            <div className="register">
                {error ? <p className="error">{error}</p> : <></>}
                {addedNewsletter ? <p className="success">Danke! Du erhältst jetzt regelmäßig Infos über benötigte Hilfe</p> : <></>}
                {!addedNewsletter ? <><input onChange={(e) => {
                    setMail(e.target.value);
                    setError("");
                }} placeholder="Email-Adresse hier eingeben"/>
                <div className="search-button" onClick={() => {
                    if(!mail) {
                        return;
                    }
                    addNewsletter("aider", mail, (r) => {
                        setAddedNewsletter(true);
                    }, (e) => {
                        setError(e);
                    })
                }}><EmailIcon/>Abonnieren</div></> : <></>}
            </div>
            <div className="next">
                <div className="search">
                    <div className="category">
                        <h2>Art</h2>
                        <MultipleSelect onChange={(category) => {
                            setCategory(category);
                            firstExecute.current = false;
                        }} placeholder="Kategorie auswählen" items={categoryNames}/>
                    </div>
                    <SearchButton/>
                </div>
                <h1>nächste Hilfegesuche</h1>
                <BoxContainer rowElements={3}>
                    {aider.map((d, i) => {
                        if(category != -1 && d.category != categories[category].value) return;
                        if(index >= aiderCount) return;
                        index++;
                        return <HelperBox aider={d}/>;
                    })}
                </BoxContainer>
                <a onClick={() => {
                    setAiderCount(aiderCount + 3);
                    firstExecute.current = false;
                }} className={"display-more " + ((maxAiderCount - realAiderCount) <= 0 ? "disabled" : "")}>mehr anzeigen</a>
            </div>
        </div>
        <Footer/>
    </>
}