import React from "react";
import SadFaceIcon from "../assets/icons/sadFace";

import "./emptyPage.css";
import Footer from "./footer";

function EmptyPage() {

    return <>
        <div className="page" id="empty-page">
            <SadFaceIcon/>
            <h1>Tut uns leid. Hier sind leider noch keine Inhalte vorhanden</h1>
            <a href="/home">Zur Startseite</a>
        </div>
        <Footer/>
    </>;
}

export default EmptyPage;