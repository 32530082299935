import { sendRequest } from "../apiManager";
import { ClubCategoryList } from "./clubCategoryList";

function getClubList(retrieveData) {

    sendRequest("club", "GET", "", (r) => {
        var categoryList = [];
        var categories = Object.keys(r.data);
        for(var i = 0; i < categories.length; i++) {
            var category = new ClubCategoryList(categories[i]);
            var clubs = Object.values(r.data)[i];
            for(var y = 0; y < clubs.length; y++) {
                category.addClub(clubs[y].id, clubs[y].name, clubs[y].logo, clubs[y].visible);
            }
            if(!category.isVisible()) continue;
            categoryList.push(category);
        }
        retrieveData(categoryList);
    }, "");
}

function getClub(id, retrieveData) {

    sendRequest("club/" + id, "GET", "", (r) => {
        if(r.status == 200) {
            return retrieveData(r.data);
        }else {
            //TODO: ERROR
        }
    }, "");

}

export {getClubList, getClub}