import React from "react";

export default function SearchIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="19.872" height="23.463" viewBox="0 0 19.872 23.463">
    <g id="Gruppe_20" data-name="Gruppe 20" transform="translate(-460.708 -675)">
      <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(462 675)" fill="none" stroke="#fff" stroke-width="3">
        <circle cx="9.29" cy="9.29" r="9.29" stroke="none"/>
        <circle cx="9.29" cy="9.29" r="7.79" fill="none"/>
      </g>
      <line id="Linie_4" data-name="Linie 4" y1="6.253" x2="4.288" transform="translate(462.357 691.079)" fill="none" stroke="#fff" stroke-width="4"/>
    </g>
  </svg>;
}