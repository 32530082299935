import { sendRequest } from "./apiManager";

function search(keywords, retrieveData) {
    sendRequest("search/" + keywords, "GET", "", (r) => {
        if(r.status == 200) {
            return retrieveData(r.data.results);
        }else {
            window.open("/error/500", "_self");
        }
    }, "");
}

export {search}