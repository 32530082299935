import React from "react";

import "./box.css";

function BoxContainer(props) {
    return <div className={"box-container" + (props.rowElements ? " elements" + props.rowElements : " elements2")}>
        {props.children}
    </div>;
}

export default BoxContainer;