import React, { useEffect, useRef, useState } from "react";
import {useParams} from "react-router-dom";
import { logoutNewsletter } from "../api/newsletterManager";
import { getText } from "../api/textManager";


import Footer from "../components/footer";
import Loader from "../components/loader";
export default function NewsletterLogout() {

    const firstExecute = useRef(false);

    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");

    const {type, key, email} = useParams();

    useEffect(() => {
        getText("newsletter_logout_title", setTitle);
        getText("newsletter_logout_subtitle", setSubtitle);
        if(firstExecute.current) return;
        firstExecute.current = true;
        logoutNewsletter(type, email, key, () => {
            setTimeout(() => {
                setSuccess(true);
            }, 500);
        });
    });

    if(!success) return <Loader/>;

    return <>
        <div className="page" id="error">
        <div className="text">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <a href="/home">zur Startseite</a>
        </div>
    </div>
    <Footer/>
    </>;
}