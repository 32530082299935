import React, { useEffect, useState } from "react";

import Box2 from "./box2";

import ClockIcon from "../../assets/icons/clock";
import LocationIcon from "../../assets/icons/location";
import { formatDate } from "../../api/apiManager";
import { getWeekday } from "../../utils/dateUtils";

function EventBox2(props) {

    const event = props.event;
    const date = props.date ? props.date : new Date(event.nextStartTime);
    const weekday = getWeekday(date);

    if(event.image && event.image.uuid) {
        event.image = event.image.account + "/" + event.image.uuid;
    }

    return <Box2 onClick={() => {
        if(props.onClick) {
            props.onClick();
            return;
        }
        window.open("/event/" + event.id, "_self");
    }} className={"hover event-box-2"} title={event.title} date={event.msg ? event.msg : formatDate(date)} img={event.image} day={event.msg ? "" : weekday}>
        {event.time ? <div className="info"><ClockIcon/><p>{event.time}</p></div> : <></>}
        {event.location ? 
        <div className="info"><LocationIcon/><p>{event.location}</p></div> : <></>}
        {event.club ? 
        <div className="info"><LocationIcon/><p>{event.club}</p></div> : <></>}
    </Box2>;

}

export default EventBox2;