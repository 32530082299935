import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../api/events/eventManager";
import ClockIcon from "../assets/icons/clock";
import LocationIcon from "../assets/icons/location";
import Footer from "../components/footer";
import ImageBox from "../components/imageRoundBox";
import Loader from "../components/loader";
import { getFutureHelperExchanges } from "../api/aiderManager";
import { formatClocktime, formatDateWithDots, formatDateWithWeekday } from "../utils/dateUtils";
import EventBox from "../components/box/eventBox";
import BoxContainer from "../components/box/boxContainer";

import "./event.css";
import HelperBox from "../components/box/helperBox";

function EventPage(props) {

    const firstExecute = useRef(false);
    const {id} = useParams();

    const [aider, setAider] = useState([]);
    const [eventData, setEventData] = useState(undefined);
    const [showAllMoreEvents, setShowAllMoreEvents] = useState(false);

    useEffect(() => {
        if(eventData != undefined) {
            return;
        }
        
        setTimeout(() => {
            getEvent(id, (data) => {
                setEventData(data);
            });
            getFutureHelperExchanges((d) => {
                var output = [];
                for(var i = 0; i < d.length; i++) {
                    var el = d[i];
                    if(!el.event) continue;
                    if(el.event.id != id) continue;
                    output.push({
                        "id": el.id,
                        "title": el.title,
                        "msg": el.always ? "ab sofort" : "",
                        "nextStartTime": el.startTime,
                        "time": el.always ? undefined : formatClocktime(new Date(el.startTime), new Date(el.endTime)),
                        "location": el.location,
                        "club": el.creator.username,
                        "category": el.category
                    });
                }
                setAider(output);
            });
        }, 500);
    })

    if(eventData == undefined) {
        return <Loader/>;
    }

    const showMoreEvents = 2;

    return <>
        <div className="page" id="event-page">
            <div className="content">
                <h2 className="date">{formatDateWithWeekday(new Date(eventData.nextStartTime))}</h2>
                <h1 className="title">{eventData.title}</h1>
                <div className="info-box">
                    <div className="infos">
                        <div className="entry">
                            <ClockIcon/>{eventData.clocktime}
                        </div>
                        {eventData.location ? 
                        <div className="entry">
                            <LocationIcon/>{eventData.location}
                        </div> : <></>}
                        <div className="entry">
                            <LocationIcon/>{eventData.author}
                        </div>
                    </div>
                    <ImageBox image={eventData.image}/>
                </div>
                <div className="text">
                    {eventData.description != null ? eventData.description.split("\n").map(e => {
                        return <p>{e}</p>;
                    }) : "Hier sollte eigentlich eine Beschreibung des Events stehen..."}
                </div>
                {Object.keys(eventData.time).length > 1 ? 
                <div className="more-events">
                    <p className="title">Weitere Termine dieser Veranstaltung</p>
                    {Object.keys(eventData.time).map((e, i) => {
                        if(!showAllMoreEvents && i > showMoreEvents) return;
                        return <p><span>{formatDateWithDots(new Date(e))}</span> {formatClocktime(new Date(e), new Date(eventData.time[e]))}</p>
                    })}
                    {(!showAllMoreEvents && Object.keys(eventData.time).length > showMoreEvents+1) ? <p className="show-all" onClick={() => {setShowAllMoreEvents(true);}}>+ {Object.keys(eventData.time).length - showMoreEvents - 1} weitere</p> : <></>}
                </div> : <></>}
            </div>
            <div className="helper">
            {aider.length > 0 ? <>
                <h3>Verknüpfte Helfergesuche</h3>
                <BoxContainer rowElements={3}>
                    {aider.map((d, i) => {
                        if(i > 2) return;
                        return <HelperBox aider={d}/>;
                    })}
                </BoxContainer>
            </> : <></>}
            </div>
        </div>
        <Footer/>
    </>;

}

export default EventPage;