class ClubCategoryList {
    constructor(name) {
        this.name = name;
        this.clubs = [];
    }

    addClub(id, name, imageLink, visible) {
        this.clubs.push(new ClubListElement(name, imageLink, id, visible));
    }

    getClubs() {
        return this.clubs;
    }

    isVisible() {
        for(var i = 0; i < this.clubs.length; i++) {
            console.log(this.clubs[i]);
            if(this.clubs[i].isVisible()) {
                return true;
            }
        }
        return false;
    }

    getName() {
        return this.name;
    }
}

class ClubListElement {
    constructor(name, imageLink, id, visible) {
        this.name = name;
        this.imageLink = imageLink;
        this.id = id;
        this.visible = visible;
    }

    isVisible() {
        return this.visible;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getImageLink() {
        return this.imageLink;
    }
}

export {ClubListElement, ClubCategoryList}