import { useEffect, useRef, useState } from "react";
import { getText } from "../api/textManager";
import Footer from "../components/footer";
import "./imprint.css";

export default function PrivacyPage() {
    const [text, setText] = useState("");
    const el = useRef();

    useEffect(() => {

        getText("privacy", setText);
        if(el.current != undefined) {
            el.current.innerHTML = text;
        }
    })
    return <>
        <div className="page" id="imprint" ref={el}>
        </div>
        <Footer/>
    </>
}