import React, { useEffect, useState } from "react";
import { getText } from "../../api/textManager";
import Box from "./box";

export default function VideoBox(props) {

    const [url, setUrl] = useState("");
    useEffect(() => {
        getText("start_kulturschneise_url", setUrl);
    })

    return <div className="box video-box">
        <h2>{props.title}</h2>
        <iframe src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
}