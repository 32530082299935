import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/icons/search";

import "./searchButton.css";

function SearchButton(props) {

    const [active, setActive] = useState(false);
    const [clicked, setClicked] = useState(false);

    const inputEl = useRef();

    const [loadedDefaultValue, setLoadedDefaultValue] = useState(false);

    useEffect(() => {
        window.addEventListener("click", () => {
            setActive(clicked);
            if(!inputEl.current) return;
            inputEl.current.focus();
        });
        if(props.defaultValue && !loadedDefaultValue) {
            inputEl.current.value = props.defaultValue;
            setLoadedDefaultValue(true);
        }
    });

    return <div className={"search-button" + (active ? " active" : "")} onClick={() => {setClicked(true);setTimeout(() => {
        setClicked(false);
    }, 1000)}}>
        {props.icon ? props.icon : <SearchIcon/>}
        <a>{inputEl.current != null && inputEl.current.value.length > 0 ? inputEl.current.value : (props.name ? props.name : "Durchsuchen")}</a>
        <input onKeyUp={(e) => {
                var keyCode = e.code || e.key;
                if(keyCode != "Enter") return;
                if(!props.onEnter) return;
                props.onEnter();
            }} type="text" placeholder={props.placeholder ? props.placeholder : "Type to filter"} ref={inputEl} onChange={(e) => {
            props.onInput(e.target.value);
        }}/>
    </div>;

}

export default SearchButton;