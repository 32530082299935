import React, { useEffect, useState } from "react";

import {useNavigate} from "react-router-dom";

import "./error.css";

import json from "../assets/json/error.json";
import { useParams } from "react-router-dom";
import Footer from "../components/footer";

import {search} from "../api/searchManager";

function ErrorPage() {

    let {code} = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState();
    const [subtitle, setSubtitle] = useState();

    const [svg, setSvg] = useState();

    useEffect(() => {
        if(!json[code]) {
            navigate("/error/404");
        }else {
            setTitle(json[code].title);
            setSubtitle(json[code].subtitle);
        }
        try {
            setSvg(require('../assets/img/error/' + code + ".svg"));
        } catch (err) {
            navigate("/error/404");
        }
    });

    return <>
        <div className="page" id="error">
        <img src={svg}/>
        <div className="text">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <a href="/home">zur Startseite</a>
        </div>
    </div>
    <Footer/>
    </>;

}

export default ErrorPage;