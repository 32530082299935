import React, { useState } from "react";
import CheckIcon from "../assets/icons/check";

import "./balCheckbox.css";

function BALCheckbox(props) {

    const [value, setValue] = useState(props.defaultValue);

    return <div className={"bal-checkbox" + (value ? " active" : "")} onClick={() => {
        setValue(!value);
        props.onChange(!value);
    }}>
        <div className="check">
            <CheckIcon/>
        </div>
        <a>{props.name}</a>
    </div>
}

export default BALCheckbox;