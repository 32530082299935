import React, { useEffect, useState } from "react";

import Box from "./box";

import ClockIcon from "../../assets/icons/clock";
import LocationIcon from "../../assets/icons/location";
import { formatDate } from "../../api/apiManager";
import { getWeekday } from "../../utils/dateUtils";

function HelperBox(props) {
    const aider = props.aider;
    const date = props.date ? props.date : new Date(aider.nextStartTime);
    const weekday = getWeekday(date);

    if(aider.image && aider.image.uuid) {
        aider.image = aider.image.account + "/" + aider.image.uuid;
    }

    return <Box onClick={() => {
        window.open("/aider/" + aider.id, "_self");
    }} className={"hover aider-box"} title={aider.title} date={aider.msg ? aider.msg : formatDate(date)} img={aider.image} day={aider.msg ? "" : weekday} rightBottom={
        <>
            {aider.time ? <div className="info"><ClockIcon/><p>{aider.time}</p></div> : <></>}
            {aider.location ? 
            <div className="info"><LocationIcon/><p>{aider.location}</p></div> : <></>}
        </>
    }>
        <p>{aider.club}</p>
    </Box>;

}

export default HelperBox;