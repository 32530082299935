import React, {useEffect, useState} from "react";
import BALCheckbox from "../components/balCheckbox";
import BalMultipleChoice from "../components/balMultipleChoice";
import Footer from "../components/footer";
import SearchInput from "../components/searchInput";
import SearchResultBox from "../components/searchResult";
import Loader from "../components/loader";

import { search } from "../api/searchManager";

import "./search.css";
import SadFaceIcon from "../assets/icons/sadFace";
import { type } from "@testing-library/user-event/dist/type";

function SearchPage() {

    const [timePeriod, setTimePeriod] = useState(3);

    const [loading, setLoading] = useState(true);
    const [lastQueue, setLastQueue] = useState("");
    const [queue, setQueue] = useState("");
    const [searchResults, setSearchResults] = useState(undefined);

    const [article, setArticle] = useState(true);
    const [event, setEvent] = useState(true);
    const [club, setClub] = useState(true);

    useEffect(() => {
        if(searchResults == undefined || lastQueue != queue) {
            if(queue.length > 0) {
                search(queue, (r) => {
                    setSearchResults(r);
                    setLastQueue(queue);
                    setTimeout(() => {
                        
                    setLoading(false);
                    }, 600);
                });

            }else {
                setSearchResults(undefined);
                setLoading(false);
            }
        }

    });

    if(loading) {
        return <Loader/>;
    }

    function checkTime(time) {
        var date = new Date(time);
        if(timePeriod == 3) return true;
        var compDate = new Date();
        var compDateAfter = new Date();
        if(timePeriod == 2) {
            compDateAfter.setFullYear(compDateAfter.getFullYear() + 1);
            compDate.setFullYear(compDate.getFullYear() - 1);
        }else if(timePeriod == 1) {
            compDateAfter.setDate(compDateAfter.getDate() + 30);
            compDate.setDate(compDate.getDate() - 30);
        }else if(timePeriod == 0) {
            compDateAfter.setDate(compDateAfter.getDate() + 7);
            compDate.setDate(compDate.getDate() - 7);
        }
        return compDate < date && compDateAfter > date;
    }

    function checkType(type) {
        type = type.toLowerCase();
        if(type == "artikel") {
            return article;
        }
        if(type == "termin") {
            return event;
        }
        if(type == "verein") {
            return club;
        }
        return false;
    }

    function getSearchResultCount() {
        var count = 0;
        for(var i = 0; i < searchResults.length; i++) {
            if(checkType(searchResults[i].type) && checkTime(searchResults[i].time)) count++;
        }
        return count;
    }


    return <>
        <div className="page" id="search">
        <div className="section">
            <h1>Suche</h1>
            <div className="searchSpace">
                <div className="results">
                    <div className="header">
                        <SearchInput placeholder="Suchbegriffe eingeben" onChange={(e) => {
                            setQueue(e.target.value);
                        }}/>
                        <p>{searchResults && getSearchResultCount() > 0 ? getSearchResultCount() == 1 ? "1 Ergebnis" : getSearchResultCount() + " Ergebnisse" : "keine Ergebnisse"}</p>
                    </div>
                    <div className="body">
                        {
                        
                            searchResults == undefined ? <div className="empty">
                            <SadFaceIcon/>
                            <p>Bitte gib ein wonach du suchst</p>
                            </div> : searchResults.length == 0 ?  <div className="empty">
                            <SadFaceIcon/>
                            <p>Wir konnten leider keine Ergebnisse finden</p>
                            </div> : searchResults.map((e) => {
                                return checkTime(e.time) &&  checkType(e.type) ? <SearchResultBox link={e.type.toLowerCase() == "artikel" ? "/article/" + e.id : e.type.toLowerCase() == "verein" ? "club/" + e.id : e.type.toLowerCase() == "termin" ? "event/" + e.id : ""} img={e.image} date={e.time} type={e.type} title={e.name} text={e.text}/> : <></>
                            })

                        }
                    </div>
                </div>
                <div className="filter">
                    <h1>Filter</h1>
                    <div className="checkbox">
                        <BALCheckbox name="Artikel" defaultValue={article} onChange={setArticle}/>
                        <BALCheckbox name="Termine" defaultValue={event} onChange={setEvent}/>
                        <BALCheckbox name="Vereine" defaultValue={club} onChange={setClub}/>
                    </div>
                    <h2>Zeitraum</h2>
                    <BalMultipleChoice onChange={setTimePeriod} defaultValue={timePeriod} options={[
                        "Woche",
                        "Monat",
                        "Jahr",
                        "Alle"
                    ]}/>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>;
}

export default SearchPage;