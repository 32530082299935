import React, { useEffect, useRef, useState } from "react";

import { getApiUrl } from "../../api/apiManager";

import "./box.css";

function Box(props) {

    const elRef = useRef();

    var imgLink = getApiUrl() + "image/" + props.img;

    useEffect(() => {
        if(elRef.current != null) {
            
            resize(elRef.current.offsetWidth, elRef.current.offsetHeight);
            window.addEventListener("resize", () => {
                if(elRef.current == null) return;
                resize(elRef.current.offsetWidth, elRef.current.offsetHeight);
            });
        }
    });

    const [respClasses, setRespClasses] = useState("");

    function resize(width, height) {
        var classes = [];
        if(width < 600) {
            classes.push("text-small");
        }
        var classesTxt = "";
        for(var s in classes) {
            classesTxt += " " + classes[s];
        }
        setRespClasses(classesTxt);
        if(props.onResize) {
            props.onResize(elRef.current.offsetWidth, elRef.current.offsetHeight);
        }
    }
    
    return <div ref={elRef} className={"box " + (props.className ? props.className : "") + (props.day ? " day" : "") + respClasses} onClick={props.onClick}>
        <div className="content">
            <div className="left">
                {props.date ? <p className="date">{props.date}{props.day ? <span>{props.day}</span> : <></>}</p> : <></>}
                <h1>{props.title}</h1>
                {props.children ? <div className="bottom-left">{props.children}</div> : <></>}
            </div>
            <div className={"right" + (props.rightBottom ? " right-bottom" : "")}>
                <div className="img" style={{
                    "backgroundImage": "url(" + imgLink + ")"
                }}/>
                {props.rightBottom ? <div className="bottom">{props.rightBottom}</div> : <></>}
            </div>
        </div>
    </div>;
}

export default Box;