import React, { useState } from "react";

import "./balMultipleChoice.css";

function BalMultipleChoice(props) {

    const [active, setActive] = useState(props.defaultValue);

    return <div className="multiple-choice">
        {props.options.map((e, i) => {
            return <a onClick={() => {setActive(i);props.onChange(i);}} className={active == i ? "active" : ""}>{e}</a>;
        })}
    </div>
}

export default BalMultipleChoice;