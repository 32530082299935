import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getHelperExchange } from "../api/aiderManager";
import { formatDate } from "../api/apiManager";
import ClockIcon from "../assets/icons/clock";
import EmailIcon from "../assets/icons/email";
import LocationIcon from "../assets/icons/location";
import UserIcon from "../assets/icons/user"
import EventBox from "../components/box/eventBox";
import Footer from "../components/footer";
import { formatClocktime } from "../utils/dateUtils";

export default function AiderInfoPage(props) {

    const {id} = useParams();

    const firstExecute = useRef(false);
    const [data, setData] = useState({});

    useEffect(() => {
        if(firstExecute.current) return;
        firstExecute.current = true;
        getHelperExchange(id, setData);

    })

    return <>
        <div className="page" id="aider-info">
        <a className="title">Hilfegesuch<span>{data.always ? "ab sofort" : formatDate(new Date(data.startTime))}</span></a>
        <h1>{data.title}</h1>
        <div className="infos">
            {data.always ? <></> : <div className="info">
                <ClockIcon/>{formatClocktime(new Date(data.startTime), new Date(data.endTime))}
            </div>}
            {data.location ? <div className="info">
                <LocationIcon/>{data.location}
            </div> : <></>}
            {data.creator ? <div className="info">
                <UserIcon/>{data.creator.username}
            </div> : <></>}
        </div>
        <div className="description">
            {data.description}
        </div>
        <div className="bottom-info">
            {data.phone || data.mail ? <div className="contact">
                <h2>Bei Interesse Kontakt aufnehmen</h2>
                {data.phone ? <div className="option"><UserIcon/> <a href={"tel:" + data.phone}>{data.phone}</a></div> : <></>}
                {data.mail ? <div className="option"><EmailIcon/> <a href={"mailto:" + data.mail}>{data.mail}</a></div> : <></>}
            </div> : <></>}
            {data.event ? <div className="event">
                <h2>Verknüpftes Event</h2>
                <EventBox event={data.event}/>
            </div> : <></>}
        </div>
    </div>
    <Footer/>
    </>;
}